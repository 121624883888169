<template>
  <r-popover
    content-class="rir-select__content rir-popover__radius"
    :disabled="disabled"
    v-model="showSelect"
  >
    <template #activator="{ on }">
      <r-input
        readonly
        :value="inputValues"
        @click.native="on.click"
        class="rir-select"
        :label="label"
        :class="{
          disabled: readonly,
        }"
      >
        <a
          class="rir-select__icon"
          ref="icon"
          slot="after"
          tabindex="0"
        >
          <r-icon
            size="16"
            fill="rocky"
            :size="20"
            icon="arrow-down"
          />
        </a>
      </r-input>
    </template>
    <r-list overflow>
      <r-list-item>
        <template>
          <label
            for="checkbox_all"
            class="rir-checkbox mb-1"
          >
            <input
              id="checkbox_all"
              type="checkbox"
              @change="choiceAll"
              v-model="allChoice"
            >
            <span
              class="rir-checkbox__input mr-1"
              style="flex-shrink: 0"
            >
              <r-icon
                size="16"
                icon="ic_bullit-ok"
                fill="amelie"
                class="rir-checkbox__selected"
              />
            </span>
            Все
          </label>
        </template>
      </r-list-item>
      <r-list-item
        v-for="checkbox of items"
        :key="checkbox.id"
      >
        <template>
          <label
            :for="'checkbox_' + checkbox.id"
            class="rir-checkbox mb-1"
          >
            <input
              :id="'checkbox_' + checkbox.id"
              type="checkbox"
              :value="checkbox"
              v-model="choicest"
              @change="changeCheckbox"
              @click="allChoice = false"
            >
            <span
              class="rir-checkbox__input mr-1"
              style="flex-shrink: 0"
            >
              <r-icon
                size="16"
                icon="ic_bullit-ok"
                fill="amelie"
                class="rir-checkbox__selected"
              />
            </span>
            {{ checkbox.value }}
          </label>
        </template>
      </r-list-item>
    </r-list>
  </r-popover>
</template>

<script>
export default {
  name: 'SelectCheckboxes',
  components: {},
  props: {
    items: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: 'Выбрать'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showSelect: false,
    item: null,
    choicest: [],
    allChoice: false
  }),
  computed: {
    inputValues() {
      /*   const arr = this.choicest.map(item => item.value);
      return arr.join('; '); */
      if (this.choicest.length > 1) {
        return `Выбрано: ${this.choicest.length}`;
      }
      return this?.choicest[0]?.value || '';
    }
  },
  watch: {},
  created() {
  },
  methods: {
    changeCheckbox() {
      this.$emit('input', this.choicest);
    },
    choiceAll() {
      if (this.allChoice) {
        this.choicest = this.$props.items;
      } else {
        this.choicest = [];
      }
    }
  }
};
</script>
<style lang="scss">
.opacity {
  opacity: 1;
}

.rir-input__input input {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.rir-list > .rir-list-item * {
  opacity: 1 !important;
}
.rir-list.overflow {
  overflow-y: auto;
  max-height: 178px;
  scrollbar-color: rgba(#04153E, 0.08) transparent;

  &::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }
}
</style>
