<template>
  <div class="loader flex align-items-center justify-center">
    <r-spinner />
  </div>
</template>

<script>
export default {
  name: 'Loader'
};
</script>

<style scoped lang="scss">
.loader {
  padding: 40px;
  width: 100%;
  height: 100%;

}
</style>
