<template>
  <router-link :to="`edit/${marker.id}`">
    <div class="r-map-balloon pointer pa-4 flex-direction-column">
      <p class="roquefort mb-1">
        {{ marker.description }}
      </p>
      <p class="сaprino opacity-72">
        {{ marker.address }}
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'BalloonCard',
  props: {
    marker: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 244px;
  &__img {
    width: 100%;
    height: 125px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    .backdrop{
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      filter: blur(10px);
      z-index: -1;
    }
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__info {

    padding: 12px 16px 0;
  }
}

</style>
