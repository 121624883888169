var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"objects"},[_c('admin-header',{staticClass:"mb-7",attrs:{"title":"Точки оповещения ГО и ЧС","back":false}}),_c('div',{staticClass:"flex mb-6"},[(this.$store.getters.userIsRoot)?_c('router-link',{staticClass:"flex align-items-center sulguni color-rocky mr-6",attrs:{"to":"add"}},[_c('r-icon',{staticClass:"mr-2",attrs:{"size":"16","fill":"rocky","icon":"add"}}),_vm._v(" Добавить вручную ")],1):_vm._e(),_c('r-button-action',{attrs:{"title":"Добавить из базы данных","icon":"add"},on:{"click":_vm.openModalUpload}})],1),_c('div',{staticClass:"flex mb-6 align-items-center"},[_c('r-select',{staticClass:"flex-1 mr-6",attrs:{"items":_vm.sortType,"label":"Тип точки оповещения"},model:{value:(_vm.selectedSortType),callback:function ($$v) {_vm.selectedSortType=$$v},expression:"selectedSortType"}}),_c('r-select',{staticClass:"flex-1 mr-6",attrs:{"items":[
        {
          id: 'BY_NAME',
          title: 'По названию'
        },
        {
          id: 'BY_RADIUS',
          title: 'По радиусу'
        }
      ],"label":"Сортировать"},model:{value:(_vm.selectedSorting),callback:function ($$v) {_vm.selectedSorting=$$v},expression:"selectedSorting"}}),_c('r-input',{staticClass:"flex-1 mr-6",attrs:{"label":"Поиск","before-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}}),_c('r-button-simple',{attrs:{"size":"larishae","icon":"menu","icon-size":"20","type":_vm.selectedView === 'map' ? 'secondary' : 'primary'},on:{"click":function($event){_vm.selectedView = 'table'}}}),_c('r-button-simple',{staticClass:"ml-2",attrs:{"size":"larishae","icon":"geopoint","type":_vm.selectedView === 'map' ? 'primary' : 'secondary',"icon-size":"20"},on:{"click":function($event){_vm.selectedView = 'map'}}})],1),(_vm.isLoading)?_c('loader'):[_c('div',{staticClass:"flex mt-2 mb-2"},[_c('div',{staticClass:"ml-auto parmigiano color-titanic opacity-48"},[_vm._v(" "+_vm._s(!!_vm.searchText ? "Найдено" : "Всего")+" "+_vm._s(_vm.countObjects)+" ")])]),(_vm.selectedView === 'map')?_c('div',{ref:"map",staticClass:"objects__map mt-6"},[(_vm.isShowMap)?_c('rir-map',{attrs:{"show-type":""}},[(_vm.filteredObjects.length > 0)?_c('div',_vm._l((_vm.filteredObjects),function(marker,index){return _c('div',{key:`_${marker.id}+${index}`},[_c('ymap-marker',{attrs:{"coords":[marker.lat, marker.lng],"marker-id":`marker_${marker.id}+${index}`,"icon":!!Number(marker.edds) ? _vm.$markerIcon('mapEdds') : _vm.$markerIcon('mapPin'),"options":{
                hideIconOnBalloonOpen: false,
              },"cluster-name":"main"},on:{"click":function($event){return _vm.onMarkerClick(marker.id)}}},[_c('balloon-card',{attrs:{"slot":"balloon","marker":marker,"data-id":marker.id},slot:"balloon"})],1),(_vm.selectedMarkerArr[marker.id])?_c('ymap-marker',{attrs:{"marker-id":'polygon' + marker.id,"marker-type":"polygon","coords":_vm.generateCoords(marker),"marker-fill":{ color: '#3d75e4', opacity: 0.16 },"marker-stroke":{ color: '#3d75e4', width: 2 }}}):_vm._e()],1)}),0):_vm._e()]):_vm._e()],1):_c('div',[_c('div',{staticClass:"objects__list mt-6"},_vm._l((_vm.filteredObjects),function(card){return _c('card',{key:card.id,attrs:{"card":card}})}),1),(_vm.countObjects < 1)?_c('not-found'):_vm._e()],1)],_c('r-modal',{ref:"modal",attrs:{"close-icon":"","fullscreen":""}}),_c('message',{ref:"message"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }