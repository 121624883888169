import ApiService from './ApiService';

export default class Api extends ApiService {
  async getUserInfo() {
    const { data } = await this._axios.get('nginxApi.php?get=user');
    // const {data} = await this._axios.get(`/auth/rest/user`);
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('/mob_ajax.php?action=geosearch', payload);
    return data;
  }

  async getUserAccesses() {
    const { data } = await this._axios.get('/auth/rest/access/GOCHS');
    return data;
  }

  async getCityId() {
    const { data } = await this._axios.get('/nginxApi.php?get=config');
    return data;
  }

  // objects
  async getAllObjects() {
    const { data } = await this._axios.post('/ajax.php?action=getSpeakers');
    return data;
  }

  async getObjectById(id) {
    const { data } = await this._axios.get(`/ajax.php?action=getSpeakers&id=${id}`);
    return data;
  }

  async operateObject(payload) {
    const { data } = await this._axios.post('/ajax.php?action=operateSpeaker', {
      ...payload,
      currentMainMenuNumber: 220
    });
    return data;
  }
}
