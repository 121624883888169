<template>
  <div id="app">
    <router-view />
    <r-notification />
  </div>
</template>

<script>
export default {
  name: 'warning-points-admin-app',
  beforeCreate() {
    // Удаляем script от пред. приложения
    document.getElementById('vue-yandex-maps') &&
      document.getElementById('vue-yandex-maps').remove();

    // Удаляем ymaps
    window.ymaps && delete window.ymaps;
  },
  async created(){
  }
};
</script>
