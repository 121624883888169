<template>
  <div class="objects">
    <admin-header
      title="Точки оповещения ГО и ЧС"
      :back="false"
      class="mb-7"
    />
    <div class="flex mb-6">
      <router-link
        class="flex align-items-center sulguni color-rocky mr-6"
        to="add"
        v-if="this.$store.getters.userIsRoot"
      >
        <r-icon
          size="16"
          fill="rocky"
          class="mr-2"
          icon="add"
        />
        Добавить вручную
      </router-link>
      <r-button-action
        @click="openModalUpload"
        title="Добавить из базы данных"
        icon="add"
      />
    </div>
    <div class="flex mb-6 align-items-center">
      <r-select
        class="flex-1 mr-6"
        :items="sortType"
        label="Тип точки оповещения"
        v-model="selectedSortType"
      />
      <r-select
        class="flex-1 mr-6"
        :items=" [
          {
            id: 'BY_NAME',
            title: 'По названию'
          },
          {
            id: 'BY_RADIUS',
            title: 'По радиусу'
          }
        ]"
        label="Сортировать"
        v-model="selectedSorting"
      />
      <r-input
        class="flex-1 mr-6"
        label="Поиск"
        v-model.trim="search"
        before-icon="search"
      />

      <r-button-simple
        size="larishae"
        icon="menu"
        icon-size="20"
        @click="selectedView = 'table'"
        :type="selectedView === 'map' ? 'secondary' : 'primary'"
      />
      <r-button-simple
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :type="selectedView === 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="selectedView = 'map'"
      />
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div class="flex mt-2 mb-2">
        <div class="ml-auto parmigiano color-titanic opacity-48">
          {{ !!searchText ? "Найдено" : "Всего" }} {{ countObjects }}
        </div>
      </div>
      <div
        v-if="selectedView === 'map'"
        class="objects__map mt-6"
        ref="map"
      >
        <rir-map
          v-if="isShowMap"
          show-type
        >
          <div v-if="filteredObjects.length > 0">
            <div
              v-for="(marker,index) of filteredObjects"
              :key="`_${marker.id}+${index}`"
            >
              <ymap-marker
                :coords="[marker.lat, marker.lng]"
                :marker-id="`marker_${marker.id}+${index}`"
                :icon="!!Number(marker.edds) ? $markerIcon('mapEdds') : $markerIcon('mapPin')"
                :options="{
                  hideIconOnBalloonOpen: false,
                }"
                cluster-name="main"
                @click="onMarkerClick(marker.id)"
              >
                <balloon-card
                  slot="balloon"
                  :marker="marker"
                  :data-id="marker.id"

                />
              </ymap-marker>
              <ymap-marker
                v-if="selectedMarkerArr[marker.id]"
                :marker-id="'polygon' + marker.id"
                marker-type="polygon"
                :coords="generateCoords(marker)"
                :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
                :marker-stroke="{ color: '#3d75e4', width: 2 }"
              />
            </div>
          </div>
        </rir-map>
      </div>
      <div
        v-else
      >
        <div class="objects__list mt-6">
          <card
            v-for="card of filteredObjects"
            :key="card.id"
            :card="card"
          />
        </div>
        <not-found v-if="countObjects < 1" />
      </div>
    </template>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
    <message ref="message" />
  </div>
</template>

<script>
import AdminHeader from '@/components/AdminHeader.vue';
import Card from '@/components/Card';
import NotFound from '@/components/NotFound';
import RirMap from '@/components/RirMap';
import ModalUpload from '@/components/ModalUpload';
import DeleteModal from '@/components/DeleteModal';
import SelectCheckboxes from '@/components/SelectCheckboxes';
import BalloonCard from '@/components/BalloonCard';
import Api from '../api/Api';
import { wordMatch } from '../helpers/utils';
import Loader from '../components/Loader';
import Message from '../components/Message';

export default {
  name: 'ObjectList',
  components: {
    Loader,
    AdminHeader,
    Card,
    NotFound,
    RirMap,
    SelectCheckboxes,
    BalloonCard,
    Message
  },
  data() {
    return {
      timeoutId: null,
      selectedView: 'table',
      searchText: '',
      selectedSorting: 'BY_NAME',
      sortType: [
        {
          id: 'ALL',
          title: 'Все'
        },
        {
          id: '1',
          title: 'ЕДДС'
        }
      ],
      selectedSortType: 'ALL',
      isShowMap: false,
      selectedMarkerId: null,
      selectedMarkerArr: {}
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.object.isLoading;
    },
    filteredObjects() {
      let list = this.$store.state.object.allObjects;

      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el?.description || '', this.searchText) <= 0.5
            || wordMatch(el?.address || '', this.searchText) <= 0.5
        );
      }
      if (this.selectedSortType !== 'ALL') {
        list = list.filter(el => el.edds === this.selectedSortType);
      }
      if (this.selectedSorting === 'BY_NAME') {
        list = list.sort((a, b) => a.description.localeCompare(b.description));
      }
      if (this.selectedSorting === 'BY_RADIUS') {
        list = list.sort((a, b) => b.radius - a.radius);
      }
      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  },
  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },
  async created() {
    await this.$store.dispatch('getAllObjects');
  },
  mounted() {
  },
  methods: {
    asd(e){
      console.log('saaaaaaaaaaaaaaaaaaaa',e)
    },
    generateCoords(value) {
      const coords = [[Number(value.lat), Number(value.lng)]];
      value.angle1 = parseInt(value.angle1, 10) % 360;
      value.angle2 = parseInt(value.angle2, 10) % 360;
      value.radius = parseInt(value.radius, 10);
      for (let alpha = value.angle1; alpha <= (value.angle2 > value.angle1 ? value.angle2 : 360 + value.angle2); alpha += 10) {
        const a = alpha * Math.PI / 180;
        const point = window.ymaps.coordSystem.geo.solveDirectProblem([value.lat, value.lng], [Math.cos(a), Math.sin(a)], value.radius);
        // console.log(alpha+" -> point=",point.endPoint);
        coords.push(point.endPoint);
      }
      return [coords];
    },
    async openDeleteModal(id) {
      await this.$rir.modal.open(DeleteModal, {
        title: 'Удалить НТО?',
        action: () => { this.delete(id); }
      });
    },
    async delete(id) {
      await new Api().operateObject({
        id,
        action: 'delete'
      }).then(() => {
        const r = this.$router.resolve({
          name: 'index'
        });
        window.location.assign(r.href);
      });
    },
    openModalUpload() {
      this.$rir.modal.open(ModalUpload);
    },
    onMarkerClick(id) {
      if (this.selectedMarkerArr[id]) {
        this.selectedMarkerArr[id] = false;
      } else {
        this.selectedMarkerId = id;
        const obj = JSON.parse(JSON.stringify(this.selectedMarkerArr));
        obj[id] = true;
        this.selectedMarkerArr = obj;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
}

.icon-div {
  display: table;
  position: relative;
}

.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

::v-deep .rir-popover {
  display: flex;
  align-items: center;

  &__activator {
    width: 100%;
  }

  .rir-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &__wrapper {
    padding: 12px;

    .rir-button {
      display: flex;
      align-items: center;

      .rir-icon {
        margin-right: 8px;
      }
    }
  }
}

::v-deep .rir-popover__content {
  padding: 8px !important;
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

::v-deep .rir-popover__content {
  background: red;
}
</style>
